import type { MergeExclusive } from 'type-fest';

import Box from '../layout/Box';

type Above = {
  above: 'mobile' | 'tablet';
};
type Below = {
  below: 'tablet' | 'desktop';
};

/**
 * INFO: throws a type error if both above and below are submitted as props
 */
export type HiddenProps = MergeExclusive<Above, Below> & {
  children: React.ReactNode;
};

const conditionsMap = {
  above: {
    mobile: { mobile: 'contents', tablet: 'none' },
    tablet: { mobile: 'contents', desktop: 'none' },
  },
  below: {
    desktop: { desktop: 'contents', tablet: 'none', mobile: 'none' },
    tablet: { desktop: 'contents', tablet: 'contents', mobile: 'none' },
  },
} as const;

export const Hidden = ({ above, below, children }: HiddenProps) => {
  const displayConditions = above ? conditionsMap.above[above] : conditionsMap.below[below];

  return <Box display={displayConditions}>{children}</Box>;
};
