import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import ReactDOM from 'react-dom';

const ModalPortal = ({ children, isOpen }) =>
  isOpen && ReactDOM.createPortal(children, document.getElementById('modal'));

ModalPortal.propTypes = forbidExtraProps({
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
});

ModalPortal.defaultProps = {
  isOpen: false,
};

export default ModalPortal;
