import { sprinkles } from '../sprinkles.css';

import { createBox } from '@dessert-box/react';
import { Slot } from '@radix-ui/react-slot';

const BoxOg = createBox({ atoms: sprinkles });

export type BoxProps = Parameters<typeof BoxOg>[0] & { asChild?: boolean };

const Box = ({ asChild = false, as = 'div', ...props }: BoxProps) => {
  if (asChild) {
    return <BoxOg {...props} as={Slot} />;
  }
  return <BoxOg {...props} as={as} />;
};

// const Box = BoxOg;

export default Box;

// type Flatten<Type> = Type extends Record<any, any> ? { [ Key in keyof Type] : Type[Key] } : Type;
// type toto = Flatten<BoxProps>
