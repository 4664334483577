import { buttonRecipe, type ButtonVariants } from './button.css';

import { cloneElement } from 'react';

import type React from 'react';

import { Slot } from '@radix-ui/react-slot';

import CircularProgress from 'Components/CircularProgress';

export type ButtonProps<AsType extends React.ElementType = React.ElementType> = {
  children: React.ReactNode;
  as?: AsType;
  asChild?: boolean;
  isLoading?: boolean;
} & ButtonVariants &
  React.ComponentPropsWithoutRef<AsType>;

const defaultElement = 'button';
const Button = <AsType extends React.ElementType = typeof defaultElement>({
  asChild = false,
  as,
  variant,
  size = 'default',
  mode = 'light',
  disabled = false,
  isLoading = false,
  children,
  padded,
  ...props
}: ButtonProps<AsType>) => {
  const Comp = asChild ? Slot : as ?? defaultElement;
  const finalChildren = isLoading ? (
    asChild ? (
      // @ts-expect-error - theoretically children can be undefined but in practice it makes no sense to have a button without children
      cloneElement(children, null, <CircularProgress />)
    ) : (
      <CircularProgress />
    )
  ) : (
    children
  );

  return (
    <Comp
      className={buttonRecipe({
        variant: isLoading || disabled ? undefined : variant,
        size,
        mode,
        padded,
        disabled: isLoading || disabled,
      })}
      disabled={isLoading || disabled}
      {...props}
    >
      {finalChildren}
    </Comp>
  );
};

export default Button;
