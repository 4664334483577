import { styled } from '@prose-ui/legacy';
import { createMakeAndWithStyles } from 'tss-react';

function useTheme() {
  return {};
}

const { makeStyles, useStyles, withStyles } = createMakeAndWithStyles({ useTheme });

const makeStylesWrapper = (styles, options) => {
  if (options) {
    return makeStyles(options)(styles);
  }
  return makeStyles()(styles);
};
const withStylesWrapper = styles => Component => withStyles(Component, styles);

/**
 * @deprecated Please use @prose-ui/styled  and @prose-ui/theme instead
 */
export { makeStylesWrapper as makeStyles, withStylesWrapper as withStyles, useStyles, styled };
