import { textLinkRecipe, type TextLinkVariants } from './text-link.css';

import type React from 'react';

import { Slot } from '@radix-ui/react-slot';

export type TextLinkProps<AsType extends React.ElementType = React.ElementType> = {
  children: React.ReactNode;
  as?: AsType;
  asChild?: boolean;
} & TextLinkVariants &
  React.ComponentPropsWithoutRef<AsType>;

const defaultElement = 'button';
const TextLink = <AsType extends React.ElementType = typeof defaultElement>({
  asChild = false,
  as,
  variant,
  mode = 'light',
  ...props
}: TextLinkProps<AsType>) => {
  const Comp = asChild ? Slot : as ?? defaultElement;

  return (
    <Comp
      className={textLinkRecipe({
        variant,
        mode: props?.disabled ? undefined : mode,
        disabled: props?.disabled ?? false,
      })}
      {...props}
    />
  );
};

export default TextLink;
